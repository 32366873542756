<template>
    <div>
        <ts-page-title
            :title="$t('transportationFee.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('transportationFee.pageTitle'),
                    href: '/admin/finance/transportation-fees'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <table class="tw-w-full tw-mb-5">
                        <thead>
                            <tr>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2 tw-w-4"
                                >
                                    {{ $t("transportationFee.no") }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                                >
                                    {{ $t("transportationFee.serviceType") }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                                >
                                    {{ $t("transportationFee.distantFrom") }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                                >
                                    {{ $t("transportationFee.distantTo") }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                                >
                                    {{ $t("transportationFee.currency") }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                                >
                                    {{ $t("transportationFee.amount") }}
                                </th>
                                <th
                                    class="tw-uppercase tw-bg-gray-100 tw-px-3 tw-py-2"
                                >
                                    {{ $t("transportationFee.chargeType") }}
                                </th>
                                <th
                                    class="tw-w-8 tw-py-2 tw-px-1 tw-bg-gray-100"
                                >
                                    <a
                                        @click.prevent="addRow"
                                        href="#"
                                        class="tw-bg-blue-400 tw-px-2 tw-py-1 tw-rounded-full tw-text-white"
                                        ><i class="fa fa-plus"></i
                                    ></a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(l, index) in model.item_list"
                                :key="index"
                                class="tw-border-b"
                            >
                                <td class="tw-px-3 tw-py-2 tw-text-center">
                                    {{ index + 1 }}
                                </td>
                                <td class="tw-px-3 tw-py-2">
                                    <a-select
                                        v-model="l.service_type_id"
                                        class="tw-w-full"
                                        :placeholder="
                                            $t('transportationFee.serviceType')
                                        "
                                        :options="serviceTypes"
                                    ></a-select>
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.service_type_id'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "item_list." +
                                                    index +
                                                    ".service_type_id"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <input
                                        v-model.number="l.distance_km_from"
                                        type="number"
                                        step="any"
                                        class="form-control"
                                        :placeholder="
                                            $t('transportationFee.distantFrom')
                                        "
                                    />
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.distance_km_from'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "item_list." +
                                                    index +
                                                    ".distance_km_from"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <input
                                        v-model.number="l.distance_km_to"
                                        type="number"
                                        step="any"
                                        class="form-control"
                                        :placeholder="
                                            $t('transportationFee.distantTo')
                                        "
                                    />
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.distance_km_to'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "item_list." +
                                                    index +
                                                    ".distance_km_to"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2">
                                    <a-select
                                        v-model.number="l.currency_id"
                                        :options="currencies"
                                        class="tw-w-full"
                                        :placeholder="
                                            $t('transportationFee.currency')
                                        "
                                    ></a-select>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <input
                                        v-model.number="l.fee_amount"
                                        type="number"
                                        class="form-control"
                                        step="any"
                                        :placeholder="
                                            $t('transportationFee.amount')
                                        "
                                    />
                                    <div
                                        class="tw-text-red-600 tw-text-xs"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index +
                                                    '.fee_amount'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                "item_list." +
                                                    index +
                                                    ".fee_amount"
                                            )
                                        }}
                                    </div>
                                </td>
                                <td class="tw-px-3 tw-py-2 tw-w-48">
                                    <select
                                        v-model="l.charge_type"
                                        class="form-control"
                                    >
                                        <option value="Fixed">Fixed</option>
                                        <option value="Per Km">Per Km</option>
                                    </select>
                                </td>
                                <td
                                    class="tw-w-4 tw-px-3 tw-py-2 tw-text-center"
                                >
                                    <a
                                        href="#"
                                        class="tw-text-red-600"
                                        @click.prevent="removeRow(index)"
                                    >
                                        <i class="fa fa-trash"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div
                            class="col-md-12 tw-flex tw-justify-end tw-space-x-3"
                        >
                            <ts-button
                                @click.prevent="
                                    $router.push({ name: 'transportation-fee' })
                                "
                                :disabled="loading"
                                >{{ $t("cancel") }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                outline
                                @click.prevent="onSaveAddNew"
                                :disabled="loading"
                                >{{ $t("saveAddNew") }}</ts-button
                            >
                            <ts-button
                                color="primary"
                                @click.prevent="onSave"
                                :disabled="loading"
                                >{{ $t("save") }}</ts-button
                            >
                        </div>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";

export default {
    name: "transactionFee",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            model: {
                item_list: []
            }
        };
    },
    computed: {
        ...mapState("finance/transportationFee", ["formModels"]),
        serviceTypes() {
            if (!this.formModels.serviceType) return [];

            return this.formModels.serviceType.map(el => ({
                value: el.service_type_id,
                label: el.service_type_name_en
            }));
        },
        currencies() {
            if (!this.formModels.currency) return [];
            return this.formModels.currency.map(el => ({
                value: el.currency_id,
                label: el.currency_code
            }));
        },
        defaultCurrency() {
            if (!this.formModels.currency) return "";
            return this.formModels.currency.find(el => el.is_default);
        }
    },
    methods: {
        ...mapActions("finance/transportationFee", [
            "getFormViewData",
            "store"
        ]),
        fetchFormView({ params, index }) {
            this.loading = true;
            this.getFormViewData({ params: params, index: index })
                .then(() => this.addRow())
                .catch(err => {
                    this.$notify({ type: "error", text: err.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addRow() {
            this.model.item_list.push({
                service_type_id: undefined,
                distance_km_from: "",
                distance_km_to: "",
                currency_id: this.defaultCurrency.currency_id,
                fee_amount: "",
                charge_type: "Fixed"
            });
        },
        removeRow(index) {
            this.model.item_list.splice(index, 1);
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.loading = true;
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        onSave() {
            this.loading = true;
            this.errors = new Errors();
            this.store(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$router.push({ name: "transportation-fee" });
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clearInput() {
            this.model.item_list = [];
            this.addRow();
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchFormView({ index: undefined, params: {} });
        });
    }
};
</script>

<style lang="scss" scoped></style>
